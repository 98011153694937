import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class EquipmentType implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;


}

export class EquipmentTypeFilter implements QueryFilter {

  name:      string | undefined;

}


export class EquipmentTypeExporter implements Exporter  {

  name;


  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
  }
}




